.aboutme {
    background-image: linear-gradient(130deg, #2048f7, #d320f7);
}

.abouttext {
    color: white;
    width: 75%;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  width: 100%;
  height: 70px;
  font-size: calc(10px + 2vmin);
  color: white;
  position: sticky;
  top: 0;
  z-index:9996;
  width: 100%;
  /* background: #c334eb; */
  /* padding: 6px; */
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.App-footer {
    background-color: #282c34;
    width: 100%;
    min-height: 5vh;
    font-size: calc(10px + 2vmin);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.App-link {
  color: #61dafb;
}

.App-mobile {
    /* overflow: hidden; */
}

.banner {
    width: 75%;
    /* padding-left: 12.5%; */
}

.banner-mobile {
    width: 95%;
}

.banner-image {
    max-width: 55vw;
}

.body-mobile {
    overflow: hidden;
}

.btn {
    margin: 3px;
}

.contentsection {
    padding-bottom: 25px;
    padding-top: 5vh;
    width: 100%;
    text-align: center;
}

.copyright {
    font-size: 12px;
    padding: 5px;
    color: #a9aaab;
}

.footertext {
    padding: 5px;
    padding-bottom: 15px;
}

app-body.h1 {
    color: red;
}

app-body.h2 {
    font-size: 55px;
    padding: 0px;
    width: 100%;
    height: 30px;
    color: white;
}

app-body.h3 {
    vertical-align: left;
}

app-body.h4 {
    margin-top: -15px;
}

.headerbanner {
    height: 70px;
    max-width: 55%;
    object-fit: cover;
}

.headerlink {
    float: left;
    display: flex;
    flex-direction: row;
    color: #f2f2f2;
    text-align: center;
    padding: 14px;
    text-decoration: none;
    font-size: 15px;
}

.headerlinks {
    max-width: 20vw;
    vertical-align: center;
}

.headerlinks-mobile {
    display: none;
}

.headersocials {
    /* background: #c334eb; */
    background: white;
    display: block;
    float: right;
    max-width: 25%;
    height: 50px;
    vertical-align: middle;
    padding: 10px;
    padding-left: 5px;
    padding-right: 5px;
}

.headersocials-mobile {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    padding-top: 3px;
    padding-bottom: 3px;
}

.leftcol {
    float: left;
    width: 35%;
    display:inline-block;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    text-align: left;
    padding-left: 13%;
    margin-right: 2%
}

.mobilecol {
    width: 80%;
}

.mobile-content {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* vertical-align: top;
    display: inline-block;
    text-align: center; */
}

.mobile-header {
    height: auto;
    /* position: fixed; */
    display: flex;
    flex-direction: column;
}

.mobile-headerbanner {
    max-width: none;
    height: 80px;
}

#modal-text {
    font-size: 12px;
    padding: 5px;
    width: 400px;
}

.of-link {
    width: 70%;
    display: block;
    margin-top: 4vh;
    margin-left: auto;
    margin-right: auto;
}

.oftext {
    color: white;
}

.fansly {
    background-image: linear-gradient(50deg, #271f80, #d320f7);
}

.portfolio {
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    line-height: 5;
    background: gray;
    width: 100%;
}

.portfolio-item {
    /* align-items: center; */
    padding: 5px;
    width: 500px !important;
    height: auto !important;
    max-width: 100vw;
 }

 .rightcol {
     float: left;
     width: 35%;
     margin-left: 2%;
 }

 .social-content {
     display: flex;
     flex-direction: row;
     text-align: left;
}

.Social-icon {
    width: 50px;
    height: 50px;
    max-height: 7vw;
    max-width: 7vw;
    float: right;
    padding-left: 3px;
    padding-right: 3px;
    vertical-align: middle;
}

.social-image {
    max-width: 100%;
    display: block;
    margin-top: 4vh;
    margin-left: auto;
    margin-right: auto;
}

.social-link {
    width: 250px !important;
    padding: 10px;
    text-align: center
}

.termsfooter {
    height: 20vh;
    display: flex;
    flex-direction: column;
    margin: 2vh;
    align-items: center;
    align-content: center;
    vertical-align: middle;
    position: sticky;
    bottom: 3;
}

.termstext {
    height: 80vh;
    overflow-y: auto;
    padding-right: 10%;
    padding-left: 5%;
}

.testimonials {
    width: 225px;
}

.textLink {
    display: inline;
    color: white;
}

.twitch {
    background-image: linear-gradient(180deg, #1b6b04, #001494);
}

.twitch-link {
    display: block;
    margin-top: 4vh;
    margin-left: auto;
    margin-right: auto;
    width: 25%;
    background: #6441a5;
    padding: 10px;
}

.twitch-logo {
    width: 100%;
}

.twitchtext {
    color: white;
}

.xbiz-link {
    width: 60px;

    /* width: 70%; */
    /* background: white; */
    /* display: block; */
    /* padding: 10px; */
    /* margin-top: 4vh; */
    /* margin-left: auto; */
    /* margin-right: auto; */
}

.xbiz-logo {
    object-fit: scale-down;
    width: 50%;
}
